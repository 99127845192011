import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
// import RenderBlock from '../../components/blocks/RenderBlock'
import BlueHeader from '../../components/common/BlueHeader'
import Container from '../../components/common/Container'
import Offers from '../../components/common/Offers'
import TextBlock from "../../components/blocks/TextBlock";

const PastWinnersPage = ({ data }) => (
  <Layout
    meta={data.defaultPage.meta}
    urls={data.defaultPage.urls}
    locale='fr'
  >
    <Header
      urls={data.defaultPage.urls}
      locale='fr'
    />

    <BlueHeader title={data.defaultPage.title} />

    <Container width={830}>
      {/* <RenderBlock blocks={data.defaultPage.blocks} /> */}
      <TextBlock text={`<h3>Concours &laquo;&nbsp;Fin pr&ecirc;t pour le printemps &raquo; de ROYALE<sup>MD </sup>2020</h3>
<p><strong>Grand prix&nbsp;:&nbsp;</strong>Le nom de la gagnante du prix de 5 500&nbsp;$ est&nbsp;:</p>
<p>Ashley Shipway</p>
<h3>Concours &laquo;&nbsp;Fin pr&ecirc;t pour le printemps &raquo; de ROYALE<sup>MD </sup>2019</h3>
<p><strong>Grand prix&nbsp;: </strong>La gagnante du grand prix de la trousse du m&eacute;nage du printemps de 5&nbsp;000&nbsp;$&nbsp;est :</p>
<p>Manda J, Ont.</p>
<h3>Concours &laquo;&nbsp;Nouvel an nouveau d&eacute;cor &raquo; de ROYALE<sup>MD</sup></h3>
<p><strong>Grand prix&nbsp;:</strong> La gagnante du grand prix de une carte-cadeau de 5&thinsp;000 $&nbsp;est :</p>
<p>Bonnie E, Ont.</p>
<h3>Concours &laquo;&nbsp;Frais et propre&nbsp;&raquo; de ROYALE<sup>MD</sup></h3>
<p><strong>Grand prix&nbsp;: </strong>La gagnante du grand prix de la trousse du m&eacute;nage du printemps de 5&nbsp;000&nbsp;$&nbsp;est :</p>
<p>Margaret G, Ont.</p>
<h3>Concours &laquo;&nbsp;Foyer en f&ecirc;te&nbsp;&raquo; de ROYALE<sup>MD</sup></h3>
<p><strong>Grand prix&nbsp;:</strong> La gagnante du grand prix de 5&thinsp;000 $ et des produits ROYALE<sup>MD</sup> pour un an est :</p>
<p><span lang="FR-CA">Diana H-J</span><span lang="FR-CA">, Ont.</span></p>`}
      />
    </Container>

    <Footer />
    <Offers />
  </Layout>
)

export const query = graphql`
query {
  defaultPage(guid: {eq:"5ebdf4cd-c7e3-4905-a0f5-9b8a5ef6e17c"}, locale: {eq:"fr"}) {
    title
    subtitle
    description
    blocks {
      __typename
      ...textBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default PastWinnersPage
